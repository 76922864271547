const END_CUSTOMER_ROLES = {
    // ADMIN: 'ADMIN',
    SECURITY_MANAGER: 'SECURITY_MANAGER',
    ANALYST: 'ANALYST',
    READ_ONLY: 'READ_ONLY',
    NONE: 'NONE',
    CUSTOM: 'CUSTOM'
}

Object.freeze(END_CUSTOMER_ROLES)
module.exports = END_CUSTOMER_ROLES
