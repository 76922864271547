export default {
    reportConfig(state) {
        if (state.fpConfig.reportConfig) {
            return state.fpConfig.reportConfig
        }
        return []
    },

    configTags(state) {
        if (state.tags.configTags) {
            return state.tags.configTags
        }
        return []
    },

    serverConfigChanges(state) {
        if (state.fpConfig.serverConfigChanges) {
            return state.fpConfig.serverConfigChanges
        }
        return []
    },

    tagValues,

    hostTags(state) {
        return function (ip) {
            const tags = []
            if (state.scanConfig && state.scanConfig.rating && state.scanConfig.rating.tags) {
                const hostTags = state.scanConfig.rating.tags // Object (key = hostIp, value = tag-array)
                for (const host of Object.keys(hostTags)) {
                    if (ip === host) {
                        for (const tag of hostTags[host]) {
                            tags.push({
                                hostIp: host,
                                tag: tag,
                                remote: true
                            })
                        }
                    }
                }
            }

            for (const tagItem of state.tags.configTags) {
                if (tagItem.hostIp === ip) {
                    tags.push(tagItem)
                }
            }
            return tags
        }
    },

    scanConfig(state) {
        if (state.scanConfig) {
            return state.scanConfig
        }
        return {}
    },

    configProcessing(state) {
        return state.fpConfig.configProcessing
    },

    tagFilterActivated(state) {
        return state.tags.tagFilter.length > 0 && Object.keys(state.tags.rating).length > 0
    },

    tagFilter(state) {
        return state.tags.tagFilter
    },

    tagRating(state) {
        return state.tags.rating
    },

    tagOverlayApplied(state) {
        return state.tags.tagOverlayApplied
    },

    tagGroup(state) {
        return state.tags.tagGroup
    },

    tagGroups(state) {
        if (state.scanConfig && state.scanConfig.rating && state.scanConfig.rating.tagGroups) {
            let loaclTagGroups = {}
            Object.keys(state.scanConfig.rating.tagGroups).forEach((key) => {
                loaclTagGroups[key] = state.scanConfig.rating.tagGroups[key]
                loaclTagGroups[key].id = key
            })
            return loaclTagGroups
        }
        return {}
    },

    snackBarVisibility(state) {
        return state.fpConfig.reportConfig.length > 0 || state.tags.configTags.length > 0 || state.fpConfig.serverConfigChanges.length > 0
    },

    itemConfig(state) {
        return function (params) {
            // params = {item, type, ip , location}

            if (params.parentUUID) {
                const index = state.fpConfig.reportConfig.findIndex((i) => i.item.uuid == params.parentUUID)
                if (index > -1) {
                    if (state.fpConfig.reportConfig[index].all) {
                        // Whole finding -> all
                        return {
                            type: 0,
                            parent: true,
                            config: state.fpConfig.reportConfig[index].all
                        }
                    } else if (state.fpConfig.reportConfig[index][params.ip]) {
                        // IP
                        return {
                            type: 1,
                            parent: true,
                            config: state.fpConfig.reportConfig[index][params.ip]
                        }
                    } else if (state.fpConfig.reportConfig[index][params.location]) {
                        // Location
                        return {
                            type: 2,
                            parent: true,
                            config: state.fpConfig.reportConfig[index][params.location]
                        }
                    }
                }
            }

            const index = state.fpConfig.reportConfig.findIndex((i) => i.item.uuid == params.item.uuid)
            if (index > -1) {
                if (state.fpConfig.reportConfig[index].all) {
                    // Whole finding -> all
                    return {
                        type: 0,
                        config: state.fpConfig.reportConfig[index].all
                    }
                } else if (state.fpConfig.reportConfig[index][params.ip]) {
                    // IP
                    return {
                        type: 1,
                        config: state.fpConfig.reportConfig[index][params.ip]
                    }
                } else if (state.fpConfig.reportConfig[index][params.location]) {
                    // Location
                    return {
                        type: 2,
                        config: state.fpConfig.reportConfig[index][params.location]
                    }
                }
            }

            return null
        }
    },

    canModifyItemConfig(state) {
        return state.canModifyItemConfig
    },

    canUseFilter(state) {
        return state.canUseFilter
    },

    rerateLocked(state) {
        return state.rerateLocked
    }
}

function tagValues(state) {
    // From hosts
    const tags = state.tags.hostTags ?? {}

    // From store
    for (const tagItem of state.tags.configTags) {
        if (tagItem.tag in tags) {
            tags[tagItem.tag] += 1
        } else {
            tags[tagItem.tag] = 1
        }
    }
    // From config
    if (state.scanConfig && state.scanConfig.rating && state.scanConfig.rating.tags) {
        const hostTags = state.scanConfig.rating.tags // Object (key = hostIp, value = tag-array)
        for (const host of Object.keys(hostTags)) {
            for (const tag of hostTags[host]) {
                if (tag in tags) {
                    tags[tag] += 1
                } else {
                    tags[tag] = 1
                }
            }
        }
    }

    return tags
}
