const PARTNER_ROLES = {
    SALES_MANAGER: 'SALES_MANAGER',
    GROUP_MANAGER: 'GROUP_MANAGER',
    ACCOUNTER: 'ACCOUNTER',
    NONE: 'NONE',
    CUSTOM: 'CUSTOM'
}

Object.freeze(PARTNER_ROLES)
module.exports = PARTNER_ROLES
